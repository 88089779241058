import { ACLS } from "../../constants/acls";

export function hasUserBasicAccess(roles: string[]): boolean {
  return roles ? roles.includes(ACLS.FM_HM_ACL) : false;
}

export function hasUserAppsAccess(roles: string[]): boolean {
  return roles ? roles.includes(ACLS.FM_HM_ACL) : false;
}

export function hasUserAdminAccess(roles: string[]): boolean {
  return roles ? roles.includes(ACLS.ADMIN_ACL) : false;
}

export const hasDashboardAccess = (roles: string[]) =>  {
  return roles ? roles.includes(ACLS.ADMIN_ACL) && 
    roles.includes(ACLS.PLATFORM_ACCOUNT_ADMIN) &&
    roles.includes(ACLS.PLATFORM_UPDATE_ACCOUNT_SCOPE)  : false
}