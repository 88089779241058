import { APP_LOGO_MAP } from "../constants/appLogoMap";
import { IApp } from "../types/app";


export class AppModel {
  private app: IApp;
  
  constructor(app: IApp) {
    this.app = app;
  }

  public getValue() {
    return this.app;
  }

  public updateApp(app: IApp) {
    this.app = app;
  }

  public getIcon(appId: string) {
    return APP_LOGO_MAP[appId];
  }

  public setBookmarked(bookmarked: boolean) {
    return this.app.bookmarked = bookmarked;
  }

  public toggleBookmark() {
    this.app.bookmarked = !this.app.bookmarked;
  }
}