import React, { useCallback } from "react";
import { Icon, Sidebar as MauiSidebar } from "maui";
import { getMenuItem } from "./constants";
import UserContext from "../../contexts/UserContext";
import { getEnabledSidebarItems, ISidebarMenuItem } from "../../common/utils/navigationUtil";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as PoweredByFindemSmall } from '../../assets/images/svgs/powered-by-findem-small.svg';
import styled from "styled-components";
import { useUrlParams } from "findem-helpers";

interface ISidebarProps {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>
}

const Sidebar = (props: ISidebarProps) => {
  const { isSidebarCollapsed, setIsSidebarCollapsed } = props;
  const { isTesting } = useUrlParams();
  const userModel = React.useContext(UserContext);

  const location = useLocation();
  
  // const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

  const sidebarItems: ISidebarMenuItem[] = React.useMemo(() => {
    return getEnabledSidebarItems(userModel.getUser(), !!isTesting);
  }, [userModel]);

  const menuItems = React.useMemo(() => {
    return sidebarItems.map((item: ISidebarMenuItem) => {
      return getMenuItem(
        <Link to={item.path} data-link={item.path}>{item.name}</Link>, 
        item.path.split('/')[1], 
        <Icon icon={item.icon} $size={18}/>
      )
    });
  } , [sidebarItems]);

  const handleCustomPathNameLogic = useCallback((pathName: string) => {
    const _pathName: string = location.pathname.replace('/', '');
    if (pathName.startsWith('rra-universe')) {
      return 'rra';
    } else if (pathName.startsWith('roadmap')) {
      return 'roadmap';
    } else if (pathName.startsWith('apps-catalog')) {
      return 'apps-catalog';
    } else if (pathName.startsWith('my-apps')) {
      return 'my-apps';
    }
    return _pathName;
  }, [location.pathname]);

  // add post message listener to handle sidebar collapse
  React.useLayoutEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data?.type === 'searchPageMsg') {
        setIsSidebarCollapsed(true);
      }
    });
  }, []);

  const goToFindem = useCallback(() => {
    window.open('https://www.findem.ai', '_blank');
  }, []);
  
  return <MauiSidebar 
    isLight={true}
    hideLogo={true}
    collapsed={isSidebarCollapsed}
    onCollapseChange={setIsSidebarCollapsed}
    expandedWidth={220}
    collapsedWidth={60}
    menuProps={{
      customPathNameLogic: handleCustomPathNameLogic,
      menuItems: menuItems
    }}
    extraContentAfter={<>
      {!isSidebarCollapsed && <PoweredByWrapper onClick={goToFindem}>
        <PoweredByFindemSmall />
      </PoweredByWrapper>}
    </>}
  />;
}

export default Sidebar;

const PoweredByWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 50px;
  cursor: pointer;
`;