import { useContext, useEffect, useMemo, useState } from "react";
import UserContext from "../../contexts/UserContext";
import { AppModel } from "../../models/app";
import { IUserPreference, UserModel } from "../../models/user";
import { appsApis } from "../../services/apis";
import { IApp } from "../../types/app";

export const useGetCatalogueApps = () => {
  const { data: catalogueApps, isLoading } = appsApis.getCatalogueApps.useQuery();
  const userModel = useContext(UserContext);

  const [apps, setApps] = useState<AppModel[]>([]);

  useEffect(() => {
    if(catalogueApps) {
      const userPrefs: IUserPreference | undefined = userModel.getUserPreference();
      const apps = catalogueApps.data.map((app: IApp) =>{
         let newApp: AppModel = new AppModel(app);
          newApp.setBookmarked(userPrefs?.apps?.includes(app.id) || app.id === 'people_finder' || false);
          return newApp;
        }
      );
      setApps(apps);
    }
  }, [catalogueApps, userModel])

  return { isLoading, apps }
}

export const useGetBookmarkedApps = (catalogueApps: AppModel[]) => {
  const bookMarkedApps: AppModel[] = useMemo(() => {
    return catalogueApps.filter((app: AppModel) => app.getValue().bookmarked);
  }, [catalogueApps])

  return bookMarkedApps 
}
