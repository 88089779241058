import { IUser } from "findem-types";
import { hasDashboardAccess, hasUserBasicAccess } from "./accessUtils";

export interface ISidebarMenuItem {
  name: string;
  path: string;
  icon: string;
  end?: boolean;
  isIframe?: boolean;
  iframe_route?: string;
}

export const HOME_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "My Apps",
  path: "/",
  icon: 'home',
  end: true,
};

export const MY_APPS_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "My Apps",
  path: `/my-apps`,
  icon: 'home',
};

export const APPS_CATALOG_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "App Catalog",
  path: `/apps-catalog`,
  icon: 'apps',
};

export const ROADMAP_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "Roadmap",
  path: `/roadmap`,
  icon: 'map',
};

export const DASHBOARD_SIDEBAR_ITEM: ISidebarMenuItem = {
  name: "Dashboards",
  path: `/dashboards`,
  icon: 'dashboard'
}


export function getEnabledSidebarItems(user: IUser, isTesting?: boolean): ISidebarMenuItem[] {
  const sidebarItems: ISidebarMenuItem[] = [];

  if (user && hasUserBasicAccess(user.roles)) {
    sidebarItems.push(MY_APPS_SIDEBAR_ITEM);
  }

  if (user && hasUserBasicAccess(user.roles)) {
    sidebarItems.push(APPS_CATALOG_SIDEBAR_ITEM);
  }

  if (user && hasUserBasicAccess(user.roles)) {
    sidebarItems.push(ROADMAP_SIDEBAR_ITEM);
  }

  if(user && hasDashboardAccess(user.roles)) {
    sidebarItems.push(DASHBOARD_SIDEBAR_ITEM)
  }

  return sidebarItems;
}
