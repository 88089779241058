import React, { useEffect, useMemo } from "react";
import { Loader, theme } from "maui";
import { ReactNode } from "react";
import { ThemeProvider } from "styled-components";
import { useCurrentUser, useGetUserPreferences } from "./customHooks/useCurrentUser";
import AppStyles from "./App.styled";
import UserContext from "./contexts/UserContext";
import { IUserPreference, UserModel } from "./models/user";
import { FullStory, init as initFullStory } from '@fullstory/browser';
import { loadScript } from "findem-helpers";
import Login from "./components/Common/Login/Login";

interface IAppProviders {
  children: ReactNode
}

const AppProviders = ({ children }: IAppProviders) => {
  const [userModel, setUserModel] = React.useState<UserModel | null>();

  // hooks
  const { data: currentUser, isLoading } = useCurrentUser();
  const { data: userPrefs, isLoading: isPrefsLoading } = useGetUserPreferences({ joid: currentUser?.uid || '' });

  const isUserLoading = isLoading || isPrefsLoading;
  const isUserModelLoading = useMemo(() => !!currentUser?.uid && !userModel, [currentUser, userModel]);

 useEffect(() => {
  if(!currentUser?.uid && !isLoading && window.location.pathname !== '/login') {
    window.location.href = '/login';
  }

  if(currentUser?.uid && window.location.pathname === '/') {
    window.location.href = '/my-apps';
  }
 }, [currentUser])

  React.useEffect(() => {
    if (currentUser?.uid && !isPrefsLoading) {
      const _user = new UserModel(currentUser);
      _user.setUserPreference(userPrefs as IUserPreference ?? {});
      setUserModel(_user);
    }
  }, [currentUser, isPrefsLoading, userPrefs]);

  React.useEffect(() => {
    // @ts-ignore
    window.AppcuesSettings = {
      enableURLDetection: true
    };
    if (currentUser?.uid) {
      loadScript('//fast.appcues.com/127109.js', () => {
        // @ts-ignore
        window.Appcues && window.Appcues.identify(currentUser.uid, {
          name: currentUser.name,
          email: currentUser.email,
          uid: currentUser.uid,
          company: currentUser.company,
          created_at: currentUser.account_creation,
          roles: currentUser.roles.join(', '),
          init_at: Date.now(),
          Persona: undefined,
          Search_Cert: undefined,
        });
      });
      FullStory('setProperties', {
        type: 'user',
        properties: {
          name: currentUser.name,
          email: currentUser.email,
          company: currentUser.company,
          created_at: currentUser.account_creation,
          roles: currentUser.roles.join(', '),
          init_at: Date.now(),
          Persona: undefined,
          Search_Cert: undefined,
        },
      });
    }
  }, [currentUser]);

  initFullStory({ orgId: 'VB5PS', devMode: process.env?.NODE_ENV === 'development' });
  if (isUserLoading || isUserModelLoading || (!currentUser?.uid && window.location.pathname !== '/login')) {
    return <Loader $fullPage={true} />;
  } else {
    return (
      <UserContext.Provider value={userModel as UserModel}>
        <ThemeProvider theme={theme}>
          <AppStyles />
          {children}
        </ThemeProvider>
      </UserContext.Provider>
    );
  }
}

export default AppProviders;