import { Avatar, Breadcrumb, Layout } from "maui";
import styled from "styled-components";

export const StyledHeader = styled(Layout.Header)`
  background-color: ${({ theme }) => theme.colors['white']};
  border-bottom: 1px solid ${({ theme }) => theme.colors['grey-300']};
  box-shadow: 1px 1px 3px 0px rgba(16, 25, 47, 0.03);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between
`;

export const BreadcrumbIconWrapper = styled.span`
  display: inline-flex;
  position: relative;
  top: 0px;
`;

export const StyledBreadcrumb = styled(Breadcrumb)`
  margin: ${({ theme }) => theme.dimensions.spacing[2]} 0;

  li {
    display: flex;
    align-items: center;
  }
`

export const BreadCrumbItem = styled(Breadcrumb.Item)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const BreadCrumbItemB = styled(BreadCrumbItem)`
  font-weight: bold;
  font-size: 18px;
`;

export const RRALogoSVG = styled.span`
  display: inline-block;
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.spacing[2]};
`
export const StyledAvatar = styled(Avatar)<{
  $bgColor?: string;
}>`
  background-color: ${({ $bgColor, theme }) => $bgColor ?? theme.colors['green-300']};
  color: ${({ theme }) => theme.colors['white']};
  cursor: pointer;
`

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.spacing[1]};
`

export const HelpSection = styled.div`
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  
  background-color: ${({ theme }) => theme.colors['white']};
  .maui-icon {
    color: ${({ theme }) => theme.colors['grey-500']};
    position: relative;
    top: -12px;
    left: 4px;
  }

  &:hover {
    background-color: #F2F4F7;
    .maui-icon {
      color: ${({ theme }) => theme.colors['black']};
    }
  }
`

export const Email = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors['grey-500']}
`

export const LabelInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.dimensions.spacing[1]};
`