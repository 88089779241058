import { router } from 'react-query-kit'
import apps from './apps';
import cardComments from './card-comments';
import roadmapCards from './roadmap-cards';
import users from './users'


const userApis = router('users', users);
const appsApis = router('apps', apps);
const roadmapCardsApis = router('roadmapCards', roadmapCards);
const cardCommentsApis = router('cardComments', cardComments);

export {
  userApis,
  appsApis,
  roadmapCardsApis,
  cardCommentsApis
};