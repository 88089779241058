import axios from "axios";
import { pubUrl } from "findem-helpers";
import { router } from 'react-query-kit'

const roadmap = {
  getRoadmapCards: router.query({
    queryHash: 'getRoadmapCards',
    fetcher: () => axios.get(`${pubUrl}/pub/api/task-board/cards`, { withCredentials: true }),
    staleTime: 1000 * 60 * 5,
  }),

  getRoadmapCardById: router.query({
    fetcher: (variables: {
      id: string
    }) => axios.get(`${pubUrl}/pub/api/task-board/card/${variables.id}`, { withCredentials: true }),
  }),

  upvoteCard: router.mutation({
    mutationFn: async (params: { ref: string }) =>
      axios.post(`${pubUrl}/pub/api/task-board/upvote`, { ref: params.ref }, { withCredentials: true })
  }),

  addRoadmapCard: router.mutation({
    mutationFn: async (params: { title: string,
      description: string,
      shortDescription: string,
      status: string,
      thumbnailImage: string;
      tags: string[],
    }) =>
      axios.post(`${pubUrl}/pub/api/task-board/card`, { 
        title: params.title,
        description: params.description,
        short_description: params.shortDescription,
        status: params.status,
        thumbnail_image: params.thumbnailImage,
        tags: params.tags,
      }, { withCredentials: true })
  }),
};

export default roadmap;