import { Button, Result } from 'maui';
import React, { Component, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const LostPath: React.FC<
  React.ComponentProps<typeof Component>
> = () => {
  const navigate = useNavigate();
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return <Result
    status='404'
    title=""
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" onClick={goBack}>Go Back</Button>}
  />
};

export default LostPath;
