import axios from "axios";
import { matchesUrl } from "findem-helpers";
import { router } from 'react-query-kit'
import { mockDataMiddleWare } from "../Middlewares/mockData";

const apps = {
  getCatalogueApps: router.query({
    fetcher: () => axios.get(`${matchesUrl}/api/catalogue_apps`, { withCredentials: true }),
    staleTime: 1000 * 60 * 60 * 24,
    use: [
      // @ts-ignore
      mockDataMiddleWare
    ]
  }),

  getUserApps: router.query({
    fetcher: () => axios.get(`${matchesUrl}/api/user_apps`, { withCredentials: true }),
    use: [
      // @ts-ignore
      mockDataMiddleWare
    ]
  }),
};

export default apps;