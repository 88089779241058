import { AxiosError } from "axios";
import { appNextUrl } from "findem-helpers";
import { notification } from "maui";
import { useCallback, useEffect, useState } from "react";
import { UserModel } from "../models/user";
import { userApis } from "../services/apis";


export enum LOGIN_TYPE {
  BASIC_AUTH = 'BASIC_AUTH',
  MICROSOFT_SSO = 'MICROSOFT_SSO'
}
export interface IAuthenticationProps {
  email: string | null;
  password: string | null;
  loginType?: LOGIN_TYPE | undefined
}

export const useCurrentUser = () => {
  const { data: currentUser, isLoading } = userApis.getCurrentUser.useQuery();
  return { data: currentUser?.data, isLoading };
}

export const useGetUserPreferences = (props: {
  joid: string
}) => {
  const { joid } = props;
  const { data: userPrefs, isLoading: isPrefsLoading } = userApis.getUserPrefs.useQuery({
    variables: {
      joid
    },
    enabled: !!joid,
  });

  return { data: userPrefs?.data.user_pref, isLoading: isPrefsLoading };
}

export const useAuthenticateUser = (props:IAuthenticationProps ) => {
  const { email, password, loginType } = props;
  
  const [isLoggingLoading, setIsLoggingLoading] = useState<boolean>(false);
  const {
    data: csrfTokenObj,
    isLoading: isCsrfLoading
  } = userApis.getCsrfToken.useQuery();

  const authenticateUser = useCallback(async () => {
    if(!loginType) {
      return;
    }

    if(loginType === LOGIN_TYPE.MICROSOFT_SSO) {
      const host = window.location.hostname.split('.')[0];
      window.location.href = `https://matches.findem.ai/auth/saml/login?_csrf=${csrfTokenObj?.data?.csrfToken ?? ''}&domain=russellreynolds.com&domain_prefix=${host}`

      return;
    }

    setIsLoggingLoading(true);

    if(!email || !password) {
      return;
    }

    try {
      const response = await userApis.authenticateUser.mutationFn({
        email,
        password,
        csrf: csrfTokenObj?.data?.csrfToken ?? ''
      });
      setIsLoggingLoading(false);

      const userObject: UserModel = new UserModel(response.data);

      if(userObject.isRRAUser()) {
        window.location.href = '/my-apps'
      } else {
        window.location.href = `${appNextUrl}`
      }
    }
    // @ts-ignore 
    catch(err: AxiosError) {
      notification.error({
        message: 'Login failed',
        description: err.response.data
      })
      setIsLoggingLoading(false);
    }
  }, [email, password, csrfTokenObj, loginType])

  useEffect(() => {
    if(isCsrfLoading) {
      return
    }
    
    if (!csrfTokenObj || !csrfTokenObj?.data?.csrfToken) {
      return;
    } else {
      const invalidBasicAuth: boolean = ((loginType === LOGIN_TYPE.BASIC_AUTH) && (!email || !password));
      if(!loginType || invalidBasicAuth)
        return;
      
      authenticateUser();
    }
  }, [csrfTokenObj, authenticateUser, isCsrfLoading, email, password, loginType])

  return {
    isLoggingLoading
  }
}