import { Middleware, QueryHook } from "react-query-kit";
import { MOCK_ENDPOINTS } from "../constants/mockData/mockEndpoints";

export const mockDataMiddleWare: Middleware<QueryHook> = useQueryNext => {
  return (options, queryClient) => {
    const isDummyEndpoint = (options.queryKey as string[]).some((key: string) => Object.keys(MOCK_ENDPOINTS).includes(key));

    if(isDummyEndpoint) {
      return useQueryNext({
        ...options,
        fetcher: () => new Promise((resolve) => {
          setTimeout(() => {
            // @ts-ignore
            resolve({
              data: MOCK_ENDPOINTS[options.queryKey.at(-1) as string]
            });
          }, 1000); 
        })
      })
    }

    return useQueryNext(options, queryClient)
  }
}