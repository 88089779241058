import axios from "axios";
import { pubUrl } from "findem-helpers";
import { router } from "react-query-kit";
import { ICardCommentSaveProps } from "../types/cardComments";

const cardComments = {
  getCommentsByCardRef: router.query({
    fetcher: (variables: { cardRef: string }) =>
      axios.get(`${pubUrl}/pub/api/task-board/comments?task_card_ref=${variables.cardRef}`, { withCredentials: true }),
  }),

  addComment: router.mutation({
    mutationFn: async (params: ICardCommentSaveProps) =>
      axios.post(`${pubUrl}/pub/api/task-board/comment`, { 
        comment: params.comment,
        task_card_ref: params.cardRef,
      }, { withCredentials: true })
  }),
}

export default cardComments;