import { Email, HelpSection, LabelInfo, RightSection, StyledAvatar, StyledHeader, UserInfo } from './Header.styled';
import Breadcrumbs from './Breadcrumbs';
import { Dropdown, Icon, Menu, MenuInfo, Tooltip } from 'maui';
import { getInitials } from 'findem-helpers';
import { UserModel } from '../../models/user';
import { useCallback, useContext, useMemo } from 'react';
import UserContext from '../../contexts/UserContext';
import { userApis } from '../../services/apis';
import useProfileColor from '../../customHooks/useProfileColors';

const AppHeader = () => {
  const userModel: UserModel = useContext(UserContext);
  const userColor = useProfileColor(userModel?.getUser().name || '');

  const userMenuOptions = useMemo(() => {
    return [
      {
        key: 'profile_info',
        label: <UserInfo>
          <StyledAvatar
            $bgColor={userColor}
          >
            {getInitials(userModel.getUser().name)}
          </StyledAvatar>

          <div>
            <div>
              <strong>
              {
                userModel.getUser().name
              }
              </strong>
            </div>
            
            <Email>
              {
                userModel.getUser().email
              }
            </Email>
          </div>
        </UserInfo>
      },
      {
        type: 'divider'
      },
      {
        key: 'submit_request',
        label: <LabelInfo>
          <Icon icon='rate_review' $size={14} />
          Submit a request
        </LabelInfo>
      },
      {
        key: 'logout',
        label: <LabelInfo>
          <Icon icon='logout' $size={14} />
          Log out
        </LabelInfo>
      }
    ]
  }, [userModel])

  const handleLogout = useCallback(async () => {
    await userApis.logoutUser.mutationFn();
    window.location.href = '/login';
  }, [])

  const handleMenuClick = useCallback((ev: MenuInfo) => {
    if(ev.key === 'profile_info') {
      return;
    }

    if(ev.key === 'logout') {
      handleLogout()
    }

    if(ev.key === 'submit_request') {
      window.open('https://wkf.ms/3vDVKyH', '_blank')
    }
  }, [handleLogout])

  const handleHelp = useCallback(() => {
    window.open('https://findem.gitbook.io/russell-reynolds-x-findem', '_blank')
  }, [])

  return (
    <StyledHeader>
      <Breadcrumbs />

      <RightSection>
        <Tooltip title='Help'>
          <HelpSection onClick={handleHelp}>
            <Icon icon='help' $size={20} />
          </HelpSection>
        </Tooltip>
        
        {userModel.getUser().name && <Dropdown
          menu={{
            // @ts-ignore
            items: userMenuOptions,
            onClick: handleMenuClick
          }}
          trigger={['click']}
        >
          <StyledAvatar
            $bgColor={userColor}
          >
            {getInitials(userModel.getUser().name)}
          </StyledAvatar>
        </Dropdown>}
      </RightSection>
    </StyledHeader>
  );
}

export default AppHeader;