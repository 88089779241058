import { IUser, IUserPreference as IUserPreferenceSchema } from "findem-types";
import { ACLS } from "../constants/acls";

export const FINDEM_EMAIL_DOMAIN = "@findem.ai";

export interface IUserPreference extends IUserPreferenceSchema {
  apps: string[];
}
export interface IUserModel extends IUser{
  userPreference?: IUserPreference;
}

// @malli to do typedef for this user model
export class UserModel {
  private user: IUserModel;
  
  constructor(user: IUser) {
    this.user = user;
  }

  public getUser() {
    return this.user;
  }

  public isAdminUser() {
    return this.user.roles.includes(ACLS.ADMIN_ACL);
  }

  public isOracleUser() {
    return this.user.roles.includes(ACLS.ORACLE_ACL);
  }

  public isFindemUser() {
    return this.user.email.endsWith(FINDEM_EMAIL_DOMAIN);
  }

  public isRRAUser() {
    return this.user.email.endsWith('@russellreynolds.com')
  }

  public updateUser(user: IUser) {
    this.user = user;
  }

  public getUserPreference() {
    return this.user.userPreference;
  }

  public setUserPreference(userPreference: IUserPreference) {
    this.user.userPreference = userPreference;
  }
}

// build user new UserModel(user)