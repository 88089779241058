import { Button, Result } from 'maui';
import React, { Component } from 'react';

const RouteRequiresLogin: React.FC<
  React.ComponentProps<typeof Component>
> = () => (
  <Result
    style={{
      height: '100vh',
    
    }}
    title="Please Login"
    subTitle="Sorry, you must be signed in to access this page."
    extra={
      <Button
        type='primary'
        size='large'
        href={`/login`}
      >
        Login Here
      </Button>
    }
  />
);

export default RouteRequiresLogin;
