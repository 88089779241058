import { ReactComponent as LinkedInLogo } from '../assets/images/svgs/LinkedIn.svg';
import { ReactComponent as BoardExLogo } from '../assets/images/svgs/BoardEx.svg';
import { ReactComponent as CapIQ } from '../assets/images/svgs/CapIQ.svg';
import { ReactComponent as PitchBookLogo } from '../assets/images/svgs/PitchBook.svg';
import { ReactComponent as RRALogo } from '../assets/images/svgs/RRA-logo.svg';
import { ReactComponent as FactivaLogo } from '../assets/images/svgs/Factiva.svg';
import { ReactComponent as GuideStarLogo } from '../assets/images/svgs/GuideStar.svg'
import { ReactComponent as WriterLogo } from '../assets/images/svgs/Writer.svg'


export const APP_LOGO_MAP: {
  [key: string]: JSX.Element;
} = {
  rra_engage: <RRALogo />,
  cit: <RRALogo />,
  beacon: <RRALogo />,
  netdocs: <RRALogo />,
  boardex: <BoardExLogo />,
  capiq: <CapIQ />,
  pitchbook: <PitchBookLogo />,
  linkedin: <LinkedInLogo />,
  guidestar: <GuideStarLogo />,
  fastpath_portal: <RRALogo />,
  factiva: <FactivaLogo />,
  rra_chatgpt: <RRALogo />,
  writer: <WriterLogo />,
  lms: <RRALogo />,
  nikkei_telecom: <RRALogo />,
  bizreach: <RRALogo />,
  mergermarket: <RRALogo />,
  bciq: <RRALogo />,
  qualtrics: <RRALogo />
}