import 'maui/dist/styles/maui.css';
import React, { useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppProviders from './AppProviders';
import { Sidebar } from './components/Sidebar';
import { Layout } from 'maui';
import Header from './components/AppHeader';
import { SidebarWrapper, StyledLayoutContent } from './App.styled';
import CustomRoutes from './Routes';
import Login from './components/Common/Login/Login';
import styled from 'styled-components';
import { COLLAPSED_SIDEBAR_WIDTH, EXPANDED_SIDEBAR_WIDTH } from './components/Sidebar/constants';

const queryClient = new QueryClient();

const App = () =>  {
  const isLogin = React.useMemo(() => {
    const {pathname} = new URL(window.location.href);
    return pathname.startsWith('/login');
  }, []);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = React.useState(false);
  
  return (<>
    <TopLayout>
      {!isLogin && <Header/>}
      <SubLayout
        isLogin={!!isLogin}
      >
        {isLogin && <Routes>
          <Route
            path={'*'}
            element={
              <Login />
            }
          />
        </Routes>}
        {!isLogin && <>
          <SidebarWrapper>
            <Sidebar
              isSidebarCollapsed={isSidebarCollapsed}
              setIsSidebarCollapsed={setIsSidebarCollapsed}
            />
          </SidebarWrapper>
          <StyledLayoutContent
            $sidebarCollapsed={isSidebarCollapsed}
          >
            <CustomRoutes
              isSidebarCollapsed={isSidebarCollapsed}
            />
          </StyledLayoutContent>
        </>}
      </SubLayout>
    </TopLayout>
  </>);
}
console.log('env==', process.env?.NODE_ENV);
const LiftedApp = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AppProviders>
        <BrowserRouter>
          <App /> 
        </BrowserRouter>
      </AppProviders>
    </QueryClientProvider>
  );
}

export default LiftedApp;

const TopLayout = styled(Layout)`
  max-height: 100vh;
  height: 100vh;
`;

const SubLayout = styled(Layout)<{
  isLogin:boolean;
}>`
  position: fixed;
  top: ${({isLogin}) => isLogin ? '0' : '64px'};
  width: 100%;
`;

