import { Icon, MenuProps } from "maui";
import { Link } from "react-router-dom";

type MenuItem = Required<MenuProps>['items'][number];

export const getMenuItem = (
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

export const menuItems: MenuItem[] = [
  getMenuItem( <Link to='/my-apps' data-link='/my-apps'>My apps</Link>, 'my-apps', <Icon icon="home" $size={18}/>),
  getMenuItem( <Link to='/apps-catalog' data-link='/apps-catalog'>Apps Catalog</Link>, 'apps-catalog', <Icon icon="apps" $size={18}/>),
  getMenuItem( <Link to='/roadmap' data-link='/roadmap'>Roadmap</Link>, 'roadmap', <Icon icon="map" $size={18}/>),
  // getItem( <Link to='/rra-universe' data-link='/rra-universe'>RRA Universe</Link>, 'rra', <Icon icon="language" $size={18}/>),
];

export const COLLAPSED_SIDEBAR_WIDTH = 60;
export const EXPANDED_SIDEBAR_WIDTH = 220;

