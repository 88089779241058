import React, { useEffect, useMemo } from 'react';
import { useGetCatalogueApps } from '../../customHooks/apps/useApps';
import { AppModel } from '../../models/app';
import { useParams } from 'react-router-dom';
import IframePage from '../Iframe/IframePage';
import { Spin } from 'maui';
import { useUrlParams } from 'findem-helpers';

const InstalledApp: React.FC = () => {
  const { id: paramId } = useParams<{ id?: string }>();
  const {url} = useUrlParams();
  
  const [launchedApp, setLaunchedApp] = React.useState<AppModel | null>(null);

  const iframeUrl = useMemo(() => {
    if(url){
      const isStaging = window.location.hostname.includes('dev')
      return isStaging ? `https://rra-staging.findem.ai${url}` : `https://rra-app.findem.ai${url}`;
    }
    return launchedApp?.getValue().launchUrl;
  }, [launchedApp, url]);

  const { isLoading, apps } = useGetCatalogueApps();

  useEffect(() => {
    if(apps && paramId){
      const app = apps.find((app: AppModel) => app.getValue().id === paramId);
      if(app){
        setLaunchedApp(app);
      }
    }
  },[apps, paramId]);

  // add post message listener to handle sidebar collapse
  React.useLayoutEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data?.type === 'searchPageMsg' || event.data?.type === 'searchesPageMsg') {
        // add url query param to main page
        const {pathName} = event.data;
        if(pathName){
          const url = new URL(window.location.href);
          url.searchParams.set('url', pathName);
          window.history.pushState({}, '', url.toString());
        }
      }
    });
  }, []);

  return (<>
    {!isLoading && iframeUrl && <IframePage iframeRoute={iframeUrl} />}
    {isLoading && <div><Spin/></div>}
  </>);
}

export default InstalledApp;