import { useRef } from "react";
import styled from "styled-components";

interface IIframePageProps {
  iframeRoute: string;
}

const IframePage: React.FC<IIframePageProps> = ({ iframeRoute }) => {
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const paramString = iframeRoute.split('?')[1];
  const queryString = new URLSearchParams(paramString);
  const urlParamsLength = queryString.size;
 
  return (
    <IframeWrapper 
      ref={iFrameRef} 
      src={`${iframeRoute}${urlParamsLength ? '&' : '?'}iframe=true`} 
      allow='clipboard-read; clipboard-write'
    />
  );
}

export default IframePage;

export const IframeWrapper = styled.iframe`
  border: none;
  height: ${({ height }) => height ? height : 'calc(100vh - 64px)'};
`;


