import { Button, Row } from 'maui';
import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;  // Use the full height of the viewport
  background: #FFFFFF;
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  img {
    height: 100vh;
    width: 100%;
  }
`;

export const Logo = styled.img`
  height: 60px;
  margin-bottom: 20px;
`;

export const Divider = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #E8E8E8;
  line-height: 0.1em;
  margin: 20px 0 20px;
`;

export const DividerText = styled.span`
  background: #FFFFFF;
  padding: 0 10px;
`;

export const StyledRow = styled(Row)`
  width: 35%;
  margin: 32px 0px;
  text-align: center;
  justify-content: center;
`;

export const HeadMessage = styled.div`
  text-align: center;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  max-width: 400px;
`;

export const StyledButton = styled(Button)`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const StyledSpan = styled.span`
  cursor: pointer;
`;