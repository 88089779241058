import React, { useCallback, useState } from 'react';
import { Form, Input, Button } from 'antd';
import { ReactComponent as RRALoginLogoIcon } from '../../../assets/images/svgs/rra-login-logo.svg';
import { ReactComponent as PoweredByFindem } from '../../../assets/images/svgs/powered-by-findem.svg';
import { Divider } from 'maui';
import { IAuthenticationProps, LOGIN_TYPE, useAuthenticateUser } from '../../../customHooks/useCurrentUser';
import { DividerText, HeadMessage, LoginContainer, PageContainer, StyledButton, StyledRow, StyledSpan } from './Login.styled';

const LoginForm = () => {
  const [loginType, setLoginType] = useState<LOGIN_TYPE | undefined>(undefined);
  
  const [formValues, setFormValues] = useState<IAuthenticationProps>({
    email: null,
    password: null,
  })

  const { isLoggingLoading } = useAuthenticateUser({
    email: formValues.email,
    password: formValues.password,
    loginType
  })

  const handleSubmit = useCallback((formValues: IAuthenticationProps) => {
    setLoginType(LOGIN_TYPE.BASIC_AUTH);
    setFormValues(formValues);
  }, [])


  const handleGoToFindem = useCallback(() => {
    window.open('https://findem.ai/', '_blank');
  }, []);

  const handleAzureLogin = useCallback(async () => {
    setLoginType(LOGIN_TYPE.MICROSOFT_SSO)
  }
  , []);

  return (
    <PageContainer>
      <LoginContainer>
        <div>
          <RRALoginLogoIcon style={{width: 200}} />
          <StyledRow>
            <HeadMessage>Welcome to your AI Powered Leadership Advisory Workspace</HeadMessage>
          </StyledRow>
          <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder="Enter your email..." />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input type="password" placeholder="Enter your password..." />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={isLoggingLoading}>
                Continue
              </Button>
            </Form.Item>
            <Divider>
              <DividerText>OR</DividerText>
            </Divider>
            <Form.Item>
              <StyledButton type="default" block icon={<RRALoginLogoIcon style={{ width: 50}} />} onClick={handleAzureLogin}>
                Continue with RRA SSO
              </StyledButton>
            </Form.Item>
          </Form>
          <StyledSpan onClick={handleGoToFindem}><PoweredByFindem /></StyledSpan>
        </div>
      </LoginContainer>
    </PageContainer>
  );
};

export default LoginForm;
