import { useState, useMemo } from 'react';
import { decodeString } from 'findem-helpers';

const colors = [
  'Tomato',
  'DodgerBlue',
  'MediumSeaGreen',
  'SlateBlue',
  'orange',
  'teal',
  'pink',
];

function useProfileColor(name: string) {
  const [color, setColor] = useState<string>('');

  useMemo(() => {
    const profileName =
      name.length ?? 0 > 25
        ? decodeString(name.slice(0, 25))
        : decodeString(name);

    const color = getProfileColorWithName(profileName);

    setColor(color);
  }, [name]);

  return color;
}

export const getProfileColorWithName = (name?: string) => {
  const primeNumber = 131;
  const hashValue = getHash(name ?? '') % primeNumber;

  const colorIndex = hashValue % colors.length;
  const color = colors[colorIndex];

  return color;
}

const getHash = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash + char) % 229;
  }
  return hash;
}

export default useProfileColor;